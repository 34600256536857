import React from "react";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function Footer(props : {lang: string}) {
  const lang = props.lang;
  return (
    <footer className="bg-lightPrimary dark:bg-darkPrimary py-6">
      <div className="container mx-auto flex flex-col md:flex-row flex-wrap justify-between items-start">
        <nav className="flex flex-col w-full md:w-1/3 mb-8 md:mb-0 md:text-left pl-4">
          <p className="font-bold text-black dark:text-light mb-4">Pages</p>
          <a href="/#accueil" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            {lang === "FR" ? "Accueil" : "Home"}
          </a>
          <a href="/#a-propos" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            {lang === "FR" ? "A propos" : "About me"}
          </a>
          <a href="/#projets" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            {lang === "FR" ? "Projets" : "Projects"}
          </a>
          <a href="/#experiences" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            {lang === "FR" ? "Expériences" : "Experiences"}
          </a>
          <a href="/#competences" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            {lang === "FR" ? "Compétences" : "Skills"}
          </a>
        </nav>

        <nav className="flex flex-col w-full md:w-1/3 md:items-start mb-8 md:mb-0 md:text-left pl-4">
          <p className="font-bold text-black dark:text-light mb-4">{lang === "FR" ? "Projets" : "Projects"}</p>

          <a target="_blank" href="/project/movie_project" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird ">
           Movie Selector
          </a>
          <a target="_blank" href="/project/accManager_project" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            AccManager
          </a>
          <a target="_blank" href="/project/accManagerRework_project" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            AccManager Rework
          </a>
          <a target="_blank" href="/project/elSword_project" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            ElSword
          </a>
          <a target="_blank" href="/project/tricount_project" className="mr-4 text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird">
            TriCount
          </a>
        </nav>


        

        <div className="flex md:items-end items-center w-full md:w-1/3 text-center md:text-left pl-4">
          <div className="flex-col w-full ">
          <p className="font-bold text-black dark:text-light text-start mb-4">Contact</p>

          <div className="flex w-full">
            <EmailIcon className="text-black dark:text-light mr-2" />
            <a
              href="mailto:contact@adrienscher.fr"
              className="text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird"
            >
              contact@adrienscher.fr
            </a>
          </div>
          <div className="flex items-center">
            <LinkedInIcon className="text-black dark:text-light mr-2" />
            <a
              href="https://www.linkedin.com/in/adrien-scher-31220b278/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird"
            >
              Adrien Scher
            </a>
          </div>
          <div className="flex items-center">
            <GitHubIcon className="text-black dark:text-light mr-2" />
            <a
              href="https://github.com/adrien99920"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird"
            >
              Adrien99920
            </a>
          </div>
          <div className="flex items-center">
            <LocationOnIcon className="text-black dark:text-light mr-2" />
            <a
              rel="noopener noreferrer"
              className="text-black dark:text-light hover:text-lightThird hover:dark:text-darkThird"
            >
              Grand Est, France
            </a>
          </div>
          </div>
        </div>
      </div>

      <div className="h-px w-4/5 mx-auto bg-light mt-12"></div>
      <div className="text-center text-black dark:text-light mt-12">
        {lang === "FR" ? "Site réalisé par Adrien SCHER, tous droits réservés" : "Site made by Adrien SCHER, all rights reserved"}
      </div>
    </footer>
  );
}
