import React from "react";
import ExperienceTab from "../components/ExperienceTab";

export default function Experience(props: {lang: string}){
    const lang = props.lang;

    return (
        <section id="experiences" className="w-full py-12 z-30 flex flex-row">


            <div  className="w-4/5 mx-auto py-12 z-30">
                <h2 className="text-3xl font-bold pb-12 text-black dark:text-light text-center">
                    {lang === "FR" ? "Mes expériences" : "My experiences"}
                </h2>
                <ExperienceTab lang={lang} />
                <div className="h-px bg-lightThird dark:bg-darkThird "></div>

            </div>


        </section>
        




    )
}