import React from "react";
import HTML from "../images/web/HTML.svg";
import JS from "../images/web/JS.svg";
import ReactIcon from "../images/web/React.svg";
import Node from "../images/web/Node.svg";
import CSharp from "../images/web/C.svg";
import NET from "../images/web/NET.svg";
import WP from "../images/web/WP.svg";
import Tailwind from "../images/web/Tailwind.svg";
import PHP from "../images/web/PHP.svg";
import Sharepoint from "../images/web/Sharepoint.svg";

import Java from "../images/logiciel/Java.svg";
import Python from "../images/logiciel/Python.svg";
import C from "../images/logiciel/C.svg";
import Flutter from "../images/logiciel/flutter.svg";

import SQL from "../images/bdd/sql.svg";
import BQ from "../images/bdd/bigquery.svg";
import looker from "../images/bdd/looker.svg";
import google from "../images/bdd/google.svg";

import equipe from "../images/soft/equipe.svg";
import flexible from "../images/soft/flexible.svg";
import gestion from "../images/soft/gestion.svg";
import methodologique from "../images/soft/methodologie.svg";
import organisation from "../images/soft/organisation.svg";
import perseverant from "../images/soft/perseverant.svg";
import priorisation from "../images/soft/priorisation.svg";
import rigeur from "../images/soft/rigeur.svg";

import equipeDark from "../images/soft/equipeDark.svg";
import flexibleDark from "../images/soft/flexibleDark.svg";
import gestionDark from "../images/soft/gestionDark.svg";
import methodologiqueDark from "../images/soft/methodologieDark.svg";
import organisationDark from "../images/soft/organisationDark.svg";
import perseverantDark from "../images/soft/perseverantDark.svg";
import priorisationDark from "../images/soft/priorisationDark.svg";
import rigeurDark from "../images/soft/rigeurDark.svg";

import SkillCategory from "../components/SkillCategory";
import Skill from "../components/Skill";


export default function Competence(props: { lang: string }) {
  const lang = props.lang;

  return (
    <section id="competences" className="w-4/5 mx-auto py-12">
      <h2 className="text-dark dark:text-light text-center text-3xl font-bold py-8 mx-auto">
        {lang === "FR" ? "Mes compétences" : "My skills"}
      </h2>

      <div className="flex flex-col md:flex-row flex-wrap justify-between">
        <SkillCategory
          className="shadow-lg dark:shadow-none"
          title={`${lang === "FR" ? "Développement Web" : "Web Development"}`}
        >
          <Skill name="HTML" icon={HTML} />
          <Skill name="Tailwind CSS" icon={Tailwind} />
          <Skill name="JavaScript" icon={JS} />
          <Skill name="PHP" icon={PHP} />
          <Skill name="C#" icon={CSharp} />
          <Skill name="React JS" icon={ReactIcon} />
          <Skill name=".NET" icon={NET} />
          <Skill name="Node JS" icon={Node} />
          <Skill name="WordPress" icon={WP} />
          <Skill name="SharePoint" icon={Sharepoint} />
        </SkillCategory>

        <SkillCategory
          className="shadow-lg dark:shadow-none"
          title={`${
            lang === "FR" ? "Développement Logiciel" : "Software Development"
          }`}
        >
          <Skill name="Java" icon={Java} />
          <Skill name="Python" icon={Python} />
          <Skill name="C" icon={C} />
          <Skill name="Flutter" icon={Flutter} />
        </SkillCategory>

        <SkillCategory
          className="shadow-lg dark:shadow-none"
          title={`${
            lang === "FR"
              ? "Gestion de données et Cloud"
              : "Data Management and Cloud"
          }`}
        >
          <Skill name="SQL" icon={SQL} />
          <Skill name="GCP" icon={google} />
          <Skill name="BigQuery ML" icon={BQ} />
          <Skill name="Looker Studio" icon={looker} />
        </SkillCategory>

        <SkillCategory
          className="shadow-lg dark:shadow-none hidden dark:block"
          title={lang === "FR" ? "Savoir-être" : "Soft Skills"}
        >
          <Skill
            name={lang === "FR" ? "Coopératif" : "Cooperative"}
            icon={equipe}
          />
          <Skill
            name={lang === "FR" ? "Flexible " : "Flexible"}
            icon={flexible}
          />
          <Skill name={lang === "FR" ? "Rigoureux" : "Rigorous"} icon={rigeur} />

          <Skill
            name={lang === "FR" ? "Organisé" : "Organized"}
            icon={organisation}
          />

          <Skill
            name={lang === "FR" ? "Méthodique" : "Methodical"}
            icon={priorisation}
          />

          <Skill
            name={lang === "FR" ? "Curieux" : "Curious"}
            icon={methodologique}
          />

          <Skill
            name={lang === "FR" ? "Entreprenant" : "Enterprising"}
            icon={gestion}
          />

          <Skill
            name={lang === "FR" ? "Persévérant" : "Persistent"}
            icon={perseverant}
          />
        </SkillCategory>

        <SkillCategory
          className="shadow-lg dark:shadow-none dark:hidden"
          title={lang === "FR" ? "Savoir-être" : "Soft skills"}
        >
         <Skill
            name={lang === "FR" ? "Coopératif" : "Cooperative"}
            icon={equipeDark}
          />
          <Skill
            name={lang === "FR" ? "Flexible " : "Flexible"}
            icon={flexibleDark}
          />
          <Skill name={lang === "FR" ? "Rigoureux" : "Rigorous"} icon={rigeurDark} />

          <Skill
            name={lang === "FR" ? "Organisé" : "Organized"}
            icon={organisationDark}
          />

          <Skill
            name={lang === "FR" ? "Méthodique" : "Methodical"}
            icon={priorisationDark}
          />

          <Skill
            name={lang === "FR" ? "Curieux" : "Curious"}
            icon={methodologiqueDark}
          />

          <Skill
            name={lang === "FR" ? "Entreprenant" : "Enterprising"}
            icon={gestionDark}
          />

          <Skill
            name={lang === "FR" ? "Persévérant" : "Persistent"}
            icon={perseverantDark}
          />
        </SkillCategory>
      </div>
    </section>
  );
}
