// Skill.js
import React from "react";

export default function Skill({ name, icon }) {
  return (
    <div className="flex flex-col items-center text-lightText dark:text-light md:mr-10 mb-4">
      <div className="h-10 w-10">
        <img
          src={icon}
          alt=""
          className="md:mr-10 object-contain mb-1 h-full w-full fill-darkThird"
        />
      </div>
      <p className="text-xs">{name}</p>
    </div>
  );
}
