import React, { useState } from "react";
import { text } from "stream/consumers";

interface ExperienceContent {
  id: number;
  titre: { fr: string; en: string };
  date: { fr: string; en: string };
  entite: string;
  description: { fr: string; en: string };
  competences: { fr: string; en: string }[];
  liste?: { fr: string; en: string }[];
}
const content = [
  {
    id: 0,
    titre: { fr: "Auto-entrepreneur", en: "Freelance" },
    date: { fr: "Avril 2024 - Présent", en: "April 2024 - Present" },
    entite: "TreeAce",
    description: { fr: 
      `
      Depuis avril 2024, j'ai créé ma micro-entreprise. Spécialisée dans les services informatiques, je développe notamment des applications web pour divers clients. Cette expérience est pour moi très enrichissante et me permet d'en apprendre davantage chaque jour. J'ai notamment pu acquérir des compétences en gestion de projet, en organisation ainsi qu'en relation et communication client. De plus, j'ai développé des compétences techniques en développement web, notamment dans divers langages tels que HTML, CSS, React.js, ainsi que dans l'utilisation de WordPress. J'ai également acquis des connaissances en hébergement de solutions web et en SEO. Le SEO est un domaine qui m’intéresse énormément et dans lequel je souhaite évoluer. J’ai pu m’y intéresser et travailler dessus à travers des cas concrets, ce qui est très enrichissant.
      `,
      en:
       `
       Since April 2024, I have established my micro-enterprise. Specializing in IT services, I notably develop web applications for various clients. This experience is highly enriching for me and allows me to learn more every day. I have particularly gained skills in project management, organization, as well as client relationship and communication. Moreover, I have developed technical skills in web development, particularly in various languages such as HTML, CSS, React.js, as well as in the use of WordPress. Additionally, I have acquired knowledge in hosting web solutions (DevOps work) and in SEO. SEO is a field that interests me greatly and in which I aim to evolve. I have been able to delve into it and work on it through real-life cases, which is very rewarding.
       ` },
   competences: [
    { fr: "Relation client", en: "Client relationship" },
    { fr: "Communication", en: "Communication" },
    { fr: "Gestion de projet", en: "Project management" },
    { fr: "Organisation", en: "Organization" },
    { fr: "Développement web", en: "Web development" },
    { fr: "SEO", en: "SEO" },
    ],
  },
  {
    id: 1,
    titre: { fr: "Alternance en informatique", en: "IT apprenticeship" },
    date: { fr: "Septembre 2023 - Présent", en: "September 2023 - Present" },
    
    entite: "Thém-is",
    description: { fr:
      `Depuis septembre 2023, j’effectue mon alternance chez Them-is. Cette expérience est très enrichissante pour moi car elle me permet d’acquérir un certain nombre de compétences techniques, notamment en développement web. J'ai eu l'opportunité de maîtriser des outils et des langages de programmation tels que React.js, .NET, C# ou encore SharePoint. J’ai eu l’occasion de participé à différentes projets : `,
      en:
      `Since September 2023, I have been completing my apprenticeship at Them-is. This experience is very enriching for me as it allows me to acquire a number of technical skills, particularly in web development. I have had the opportunity to master tools and programming languages such as React.js, .NET, C#, and SharePoint. I have been involved in various projects:`,
    },
    liste: [
      { fr: "Participation à une application web e-commerce", en: "Participation in an e-commerce web application" },
      { fr: "Réalisation d’une application SharePoint interne en autonomi à l’aide de React.js", en: "Development of an internal SharePoint application independently using React.js" },
    ],
    competences: [
      { fr: "Développement web", en: "Web development" },
      { fr: "React.js", en: "React.js" },
      { fr: ".NET", en: ".NET" },
      { fr: "C#", en: "C#" },
      { fr: "SharePoint", en: "SharePoint" },
    ],
  },
  {
    id: 2,
    titre: { fr: "Informaticien ", en: "Computer scientist" },
    date : { fr: "Été 2023", en: "Summer 2023" },
    entite: "FM Logistic",
    description: 
    {
      fr: `
      À la suite de mon stage de 2ème année de BUT Informatique chez FM Logistic, j’ai été embauché en CDD pendant l’été 2023. Cette expérience m'a permis de réaliser de nombreux projets :
      `,
      en: `
      Following my 2nd year internship in Computer Science at FM Logistic, I was hired on a fixed-term contract during the summer of 2023. This experience allowed me to undertake numerous projects:
      `,
    },
    liste: [
      { fr: "Travaux sur la Google Cloud Platform.", en: "Work on the Google Cloud Platform." },
      { fr: "Réalisation d’un outil de Machine Learning via BigQuery ML permettant de faire des prédictions.", en: "Development of a Machine Learning tool via BigQuery ML enabling predictions." },
      { fr: "Analyse de données.", en: "Data analysis." },
      { fr: "Développement de scripts Python.", en: "Python script development." },
    ],
    competences: [
      { fr: "Google Cloud Platform", en: "Google Cloud Platform" },
      { fr: "BigQuery ML", en: "BigQuery ML" },
      { fr: "Analyse de données", en: "Data analysis" },
      { fr: "Développement Python", en: "Python development" },
    ],
  },
  {
    id: 3,
    titre: { fr: "Stage en informatique", en: "IT internship" },
    date : { fr: "Février 2023 - Avril 2023", en: "February 2023 - April 2023" },
    
    entite: "FM Logistic",
    description: 
    {
      fr: `
      Mon stage de 2ème année de BUT Informatique chez FM Logistic a été une expérience très importante pour moi, puisqu'il s'agissait de ma première expérience professionnelle dans le domaine de l’informatique. Cette opportunité m'a permis d'en apprendre davantage sur le monde professionnel et de recevoir de précieux conseils pour la suite de ma carrière. J'ai également eu l'opportunité de réaliser de nombreux projets :
      `,
      en: `
      My 2nd-year internship in Computer Science at FM Logistic was a very important experience for me, as it was my first professional experience in the field of IT. This opportunity allowed me to learn more about the professional world and receive valuable advice for my future career. I also had the opportunity to undertake numerous projects:
      `,
    },
    
    liste : [
      { fr: "Travaux sur la Google Cloud Platform (BigQuery, Cloud Functions, Bucket, …).", en: "Work on the Google Cloud Platform (BigQuery, Cloud Functions, Bucket, …)." },
      { fr: "Analyse de données.", en: "Data analysis." },
      { fr: "Migration de données.", en: "Data migration." },
      { fr: "Développement de scripts Python.", en: "Python script development." },
      { fr: "Création de tableaux de bord (Google Looker Studio).", en: "Creation of dashboards (Google Looker Studio)." },
    ],
    
    competences: [
      { fr: "Google Cloud Platform", en: "Google Cloud Platform" },
      { fr: "BigQuery", en: "BigQuery" },
      { fr: "Cloud Functions", en: "Cloud Functions" },
      { fr: "Python", en: "Python" },
      { fr: "Google Looker Studio", en: "Google Looker Studio" },
    ],
  },
];

export default function ExperienceTab(props: { lang: string }) {
  const [currentSection, setCurrentSection] = useState(0);
  const lang = props.lang;

  return (
    <>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex flex-row w-full md:w-1/2 h-16 md:h-full">
          {[0, 1].map((index) => (
            <div
              key={index}
              onClick={() => setCurrentSection(index)}
              className={`w-1/2 h-16 flex justify-center items-center ${
                index === currentSection
                  ? "bg-lightPrimary dark:bg-darkPrimary text-lightThird dark:text-darkThird"
                  : "bg-lightSecondary dark:bg-darkSecondary text-black dark:text-light transition-colors duration-500  hover:bg-lightPrimary hover:dark:bg-darkPrimary"
              }`}
            >
              <p className="text-center">{`${
                lang === "FR" ? content[index].titre.fr : content[index].titre.en
              }`}</p>
            </div>
          ))}
        </div>
        <div className="flex flex-row w-full md:w-1/2 h-16 md:h-full">
          {[2, 3].map((index) => (
            <div
              key={index}
              onClick={() => setCurrentSection(index)}
              className={`w-1/2 h-16 flex justify-center items-center ${
                index === currentSection
                ? "bg-lightPrimary dark:bg-darkPrimary text-lightThird dark:text-darkThird"
                : "bg-lightSecondary dark:bg-darkSecondary text-black dark:text-light transition-colors duration-500  hover:bg-lightPrimary hover:dark:bg-darkPrimary"
            }`}
            >
              <p className="text-center">{`${
                lang === "FR" ? content[index].titre.fr : content[index].titre.en
              }`}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={`shadow-lg dark:shadow-none bg-lightPrimary dark:bg-darkPrimary min-h-96 w-full flex`}>
        {content.map((item, key) => (
          <div
            key={key}
            className={`h-full w-full text-dark dark:text-light p-8 ${
              key === currentSection ? "opacity-100" : "opacity-0 hidden"
            }`}
          >
            <h2 className="text-2xl">{item.entite}</h2>
            <p className="text-sm text-lightThird dark:text-darkThird">
              {lang === "FR" ? item.date.fr : item.date.en}
            </p>
            <p className="mt-6">{lang === "FR" ? item.description.fr : item.description.en}</p>
            
            {
              item.liste && 
              <ul className="list-disc ml-6 mt-6">
                {item.liste.map((point, key) => (
                  <li key={key}>{lang === "FR" ? point.fr : point.en}</li>
                ))}
              </ul>
            }
            

            <div className="flex flex-row flex-wrap mt-6">
              {item.competences.map((competence, key) => (
                <div
                  key={key}
                  className="text-sm bg-lightSecondary dark:bg-darkSecondary text-light dark:text-light p-2 rounded-lg mr-2 mb-2  hover:dark:text-darkThird hover:bg-lightPrimary hover:text-lightThird hover:dark:bg-darkPrimary transition-all duration-300"
                >
                  {lang === "FR" ? competence.fr : competence.en}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
