import { ReactElement } from "react";
import Accueil from "../sections/Accueil"
import APropos from "../sections/Apropos"
import Projects from "../sections/Projects"
import Experience from "../sections/Experience"
import Competence from "../sections/Competence"

export interface Content{
    id: string;
    titre: string;
    title: string;
    element: () => ReactElement
}




export const sections: Content[] = [
    {
        id: "accueil",
        titre: "Accueil",
        title: "Home",
        element: Accueil
    },
    {
        id: "a-propos",
        titre: "A propos",
        title: "About me",
        element: APropos
    },
    {
        id: "projets",
        titre: "Projets",
        title: "Projects",
        element: Projects
    },
    {
        id: "experiences",
        titre: "Expériences",
        title: "Experiences",
        element: Experience
    },
    {
        id: "competences",
        titre: "Compétences",
        title: "Skills",
        element: Competence
    },
]