import React from "react";
import { projects } from "../contents/projects.ts";
import film from "../images/projet_film_dark.png";
import CircleHoverButton from "../components/CircleHoverButton.tsx";

export default function Projects(props: {lang: string}) {
  const lang = props.lang;

  return (
    <div id="projets" className="w-full px-4 md:w-4/5 mx-auto py-12">
      <h2 className="text-black dark:text-light text-center text-3xl font-bold pt-8 pb-2">
         {lang === "FR" ? "Mes projets" : "My projects"}
      </h2>
      <p className="text-sm text-black dark:text-light pb-8 text-center">
        {lang === "FR"
          ? `En parallèle de mes études et de mes expériences professionnelles, je réalise de nombreux projets personnels. Voici quelques-uns d’entre eux.`
          : `Alongside my studies and professional experiences, I undertake numerous personal projects. Here are a few of them.`
          }
        </p>

      <div className="flex flex-wrap justify-between ">
        {projects.map((project, index) => (
          
          <a key={index} 
          className="transition-all	duration-500 hover:scale-95 shadow-lg dark:shadow-none w-full md:w-[49%] px-4 mb-8 bg-lightPrimary dark:bg-darkPrimary rounded-sm border-l-2 border-lightThird dark:border-darkThird"
          href={`/project/${project.id}`} target="_blank">
            <div className="flex flex-col md:flex-row items-center rounded-lg p-4">
              <div className="w-full md:w-1/3">
                <div className="mx-auto md:mx-0 mb-4 md:mb-0  w-28 h-28 rounded-full overflow-hidden dark:bg-darkPrimary p-2">
                  <img
                    src={film}
                    className="object-cover w-full h-full"
                    alt=""
                  />
                </div>
              </div>

              <div className="w-full md:w-2/3 text-black dark:text-light">
                <h2 className="text-lg font-bold pb-2 md:text-left text-center">
                  {
                    project.titre
                  } 
                </h2>
                <p className="text-sm pb-3 md:text-left text-center">
                  {
                    lang === "FR" ? project.shortDescription.fr : project.shortDescription.en
                  }
                  </p>
                
                <CircleHoverButton href={`/project/${project.id}`} className="rounded-lg w-fit md:mx-0 mx-auto hover:text-light hover:dark:text-black" 
                texte={`${lang === "FR" ? "En savoir plus" : "Learn more"}`} 
                textColor={"text-black dark:text-light"} bgColor={"bg-lightSecondary dark:bg-darkSecondary"} beforBgColor={"before:bg-lightThird before:dark:bg-darkThird"} w={"w-5/12 lg:w-fit"} px="px-4" py="py-1"/>

              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
