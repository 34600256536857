import { useEffect, useState } from "react";
import { Project, projects } from "../contents/projects.ts";
import React from "react";
import Galerie from "../components/Galery.tsx";

export default function ProjectDetail(props : {projectId: string, lang: string, sendPageView: any}) {
  const [project, setProject] = useState<Project | null>();
  const { projectId, lang, sendPageView } = props;
  
  useEffect(() => {
    setProject(projects.filter((projet) => projet.id === projectId)[0]);
  }, [projectId]);

  useEffect(() => {
    if(project){
      sendPageView(`/${project?.id}`, project.titre);
    }
  }, [project]);



  return (
    <section className="w-4/5 mx-auto">
      {project && (
        <div className="py-12">
          <a href="/#projets" className="text-dark dark:text-light"> 
          {
            lang === "FR" ? "Retour à l'accueil" : "Back to home"
          }
          </a>
          <h2 className="text-3xl font-bold pb-6 pt-6 md:pt-0 md:pb-12 text-lightSecondary dark:text-light text-center">
            {project.titre}
          </h2>
          {
            project.video &&
<video
            src={project.video}
            controls
            className="w-full h-screen object-cover py-8"
          ></video>
          }
          
          {
            project.images && 
            <div className="py-8">
            <Galerie images={project.images} />
            </div>
          }

          <div className="w-full flex flex-col md:flex-row justify-between pb-6 md:pb-12">
            <div className="w-full md:w-2/3 md:pr-12">
              <h3 className="text-xl font-bold pb-6 text-lightSecondary dark:text-light ">Description</h3>
              <p className="text-dark text-justify dark:text-light md:pr-6">
                {
                  lang === "FR" ? project.description.fr : project.description.en
                }
              </p>
            </div>
            <div className="w-full md:w-1/3 ">
            <h3 className="text-xl font-bold pt-6 md:pt-0 pb-6 text-lightSecondary dark:text-light ">
              {
                lang === "FR" ? "Technologies" : "Technologies"
              }
            </h3>
              <ul className="flex flex-row flex-wrap pb-6">
              {project.technos.map((techno, index) => (
                <li key={index} className="bg-lightSecondary dark:bg-darkSecondary text-light dark:text-light p-2 rounded-lg mr-2 mb-2  hover:dark:text-darkThird hover:bg-light hover:text-lightThird hover:dark:bg-dark transition-all duration-300">
                  {techno}
                </li>
              ))}
              </ul>
            </div>
          </div>

          <h3 className="text-xl font-bold pb-6 text-lightSecondary dark:text-light ">
            {
              lang === "FR" ? project.explicationTitre.fr : project.explicationTitre.en
            }
            </h3>

              <div className="flex flex-row flex-wrap ">
                {
                  project.explicationCard.map((fonction, index) => (
                    <div key={index} className="flex flex-col w-[49%] mr-[1%] mb-[1%] md:w-[32%] lg:w-[24%] xl:w-[19%] p-6 rounded-md bg-darkPrimary">
                      <div className="text-2xl pb-2 text-center text-lightThird dark:text-darkThird font-bold">
                        {fonction.numero}
                      </div>
                      <div className="text-xs text-center text-dark dark:text-light">
                        {
                          lang === "FR" ? fonction.fr : fonction.en
                        }
                      </div>
                    </div>
                  ))
                }
                
              </div>
        </div>
      )}
    </section>
  );
}
