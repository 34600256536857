import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import ProjectDetail from './projects/ProjectDetail';
import Router from './pages/Router';


export default function App() {
    return(
        <Router/>
    );
}