import { ReactNode } from "react";

import image from "../images/projet_film_dark.png";


// projet film images
import film1 from "../images/film/video.mp4";

// projet accManager images
import acc1 from "../images/projetCompte/1.png";
import acc2 from "../images/projetCompte/2.png";

// refonte accManager images
import accRework1 from "../images/refonteCompte/1.png";
import accRework2 from "../images/refonteCompte/2.png";
import accRework3 from "../images/refonteCompte/3.png";
import accRework4 from "../images/refonteCompte/4.png";
import accRework5 from "../images/refonteCompte/5.png";

// projet ElSword images
import elsword1 from "../images/jeuPython/principal.png";
import elsword2 from "../images/jeuPython/1.png";
import elsword3 from "../images/jeuPython/jeu.mp4";

export interface Project {
  id: string;
  titre: string;
  shortDescription: { fr: string; en: string };
  image: string;
  description: { fr: string; en: string };
  technos: string[];
  images?: { img: string; alt: string }[];
  explicationTitre: { fr: string; en: string };
  explicationCard: 
    { fr: string; en: string; numero: string }[];  
  video?: string;
}

export const projects: Project[] = [
  {
    id: "movie_project",
    titre: "Movie Selector",
    shortDescription: {
      fr: "Application web permettant à un groupe de se mettre d'accord sur un film à regarder ensemble.",
      en: "A web application that allows a group to agree on a movie to watch together.",
    },
    image: image,
    description: {
      fr: `
            Après avoir rencontré des difficultés lors de la sélection d'un film à regarder avec des amis, j'ai décidé de créer une application pour résoudre ce problème. Ce projet vise à faciliter la prise de décision collective lors du choix d'un film à regarder en groupe. Les utilisateurs peuvent créer une session, obtenir un code unique et inviter leurs amis à rejoindre cette session. Une fois réunis, ils peuvent voter pour des films proposés aléatoirement. Si un consensus est atteint sur un film, celui-ci est sélectionné pour être regardé. Sinon, le processus de vote se poursuit jusqu'à ce qu'un accord soit trouvé.

Ce projet était très intéressant car il m’a permis d’apprendre à utiliser React.js pour la partie front-end. Pour la partie back-end, j’ai créé une petite API pour interagir avec ma base de données en utilisant Node.js et Express.js. J’ai également appris à utiliser les WebSockets via la bibliothèque Socket.IO pour faire en sorte que les utilisateurs reçoivent les informations sur les films en temps réel. Lorsque l'utilisateur crée ou rejoint une session, une connexion WebSocket est établie. Lorsqu'un utilisateur vote pour un film, ce vote est transmis via le WebSocket au serveur, qui diffuse ensuite les résultats actualisés à tous les autres clients connectés à cette session.
            `,
      en: `
            After experiencing difficulties in selecting a movie to watch with friends, I decided to create an application to solve this problem. This project aims to facilitate collective decision-making when choosing a movie to watch as a group. Users can create a session, obtain a unique code, and invite their friends to join this session. Once gathered, they can vote for randomly suggested movies. If a consensus is reached on a movie, it is selected to be watched. Otherwise, the voting process continues until an agreement is found.

This project was very interesting because it allowed me to learn how to use React.js for the front-end part. For the back-end part, I created a small API to interact with my database using Node.js and Express.js. I also learned to use WebSockets via the Socket.IO library to ensure users receive real-time information about the movies. When a user creates or joins a session, a WebSocket connection is established. When a user votes for a movie, this vote is transmitted via WebSocket to the server, which then broadcasts the updated results to all other clients connected to that session.
            `,
    },
    video: film1,
    technos: ["React.js", "Node.js", "Express.js", "Socket.io", "MySQL"],
    explicationTitre: { fr: "Parcours utilisateur", en: "User flow" },
    explicationCard: 
        [
            { fr: "L'utilisateur se connecte.", en: "The user logs in.", numero: "01" },
            { fr: "Il crée une session.", en: "They create a session.", numero: "02" },
            { fr: "Il obtient un code d'identification.", en: "They receive an identification code.", numero: "03" },
            { fr: "Il partage le code avec ses amis.", en: "They share the code with their friends.", numero: "04" },
            { fr: "Les amis rejoignent la session.", en: "Friends join the session.", numero: "05" },
            { fr: "Le propriétaire lance la session.", en: "The host starts the session.", numero: "06" },
            { fr: "Des films sont proposés pour le vote.", en: "Movies are suggested for voting.", numero: "07" },
            { fr: "Chaque participant vote pour les films proposés (oui ou non).", en: "Each participant votes for the suggested movies (yes or no).", numero: "08" },
            { fr: "Le processus de vote est répété jusqu'à ce qu'un accord soit trouvé.", en: "The voting process is repeated until an agreement is reached.", numero: "09" }
        ]
          
    
  },

  {
  id: "accManager_project",
    titre: "Bankk",
    shortDescription: {
      fr: "Logiciel permettant de gérer ses comptes bancaires (revenus et dépenses).",
      en: "Software for managing finances (income and expenses).",
    },
    image: image,
    description: {
      fr: `
      L'idée m'est venue car je souhaitais utiliser une application permettant de gérer mes comptes. J'ai donc décidé de créer moi-même l'application que j'allais utiliser. Cette application permet donc en un coup d’œil de voir l'état de ses comptes à l’aide de graphiques. Ayant étudié JavaFX la même année à l'IUT Nancy Charlemagne, j’ai donc décidé d’utiliser cette technologie pour la réaliser, en adoptant une architecture Modèle-Vue-Contrôleur (MVC). Ce projet m'a permis de développer mes compétences en développement objet et en conception logicielle, en passant par une phase de conception préliminaire avec la réalisation de diagrammes de classe, etc.`,
      en: `
      The idea came to me because I wanted to use an application to manage my finances. So, I decided to create the application myself. This application allows users to get an overview of their accounts using charts. Having studied JavaFX the same year at IUT Nancy Charlemagne, I decided to use this technology to develop it, adopting a Model-View-Controller (MVC) architecture. This project allowed me to develop my skills in object-oriented development and software design, starting with a preliminary design phase involving the creation of class diagrams, etc.`,
    },
    images: [{img: acc1, alt: "Image du projet AccManager"}, {img: acc2, alt: "Image du projet AccManager"}],
    technos: ["Java", "JavaFX"],
    explicationTitre: { fr: "Fonctionnalités", en: "Features" },
    explicationCard: 
        [
          { fr: "Liste des dépenses", en: "List of expenses", numero: "01" },
          { fr: "Liste des revenus", en: "List of income", numero: "02" },
          { fr: "Balance des dépenses et revenus", en: "Balance of expenses and income", numero: "03" },
          { fr: "Répartition des dépenses par catégories", en: "Distribution of expenses by categories", numero: "04" },
          { fr: "Répartition des revenus par catégories", en: "Distribution of income by categories", numero: "05" },
          { fr: "Choix des dates pour chaque graphique", en: "Selection of dates for each display", numero: "06" },
          { fr: "Ajout de nouvelles dépenses et de nouveaux revenus", en: "Addition of new expenses and income", numero: "07" },
        ],
  },

  {
    id: "accManagerRework_project",
      titre: "Bankk rework",
      shortDescription: {
        fr: "Refonte de l’application de gestion des comptes en utilisant des technologies web.",
        en: "Redesign of the account management application using web technologies.",
      },
      image: image,
      description: {
        fr: `
        Après plusieurs utilisations de mon application de gestion des comptes développée en JavaFX, j'ai identifié plusieurs améliorations nécessaires. Tout d’abord, je voulais pouvoir accéder à davantage d'informations telles que mes dépenses moyennes par mois, mes revenus moyens par mois et la possibilité de définir et suivre des objectifs financiers sur des périodes données. Un autre défi majeur était la disponibilité limitée de l'application, ce qui était contraignant. La solution idéale était de reconstruire l’application en utilisant des technologies web, offrant une accessibilité universelle depuis n'importe quel appareil.
        `,
        en: `
        After several uses of my account management application developed in JavaFX, I identified several necessary improvements. Firstly, I wanted to access more information such as my average monthly expenses, average monthly income, and the ability to set and track financial goals over given periods. Another major challenge was the limited availability of the application, which was restrictive. The ideal solution was to rebuild the application using web technologies, providing universal accessibility from any device.`,
      },
      images: [{img: accRework1, alt: "Image du rework d'AccManager"}, {img: accRework2, alt: "Image du rework d'AccManager"}, {img: accRework3, alt: "Image du rework d'AccManager"}, {img: accRework4, alt: "Image du rework d'AccManager"}, {img: accRework5, alt: "Image du rework d'AccManager"}],
      technos: ["Java", "JavaFX"],
      explicationTitre: { fr: "Fonctionnalités", en: "Features" },
      explicationCard: 
        [
          { fr: "Liste des dépenses", en: "List of expenses", numero: "01" },
          { fr: "Liste des revenus", en: "List of income", numero: "02" },
          { fr: "Balance des dépenses et revenus", en: "Balance of expenses and income", numero: "03" },
          { fr: "Répartition des dépenses par catégories", en: "Distribution of expenses by categories", numero: "04" },
          { fr: "Répartition des revenus par catégories", en: "Distribution of income by categories", numero: "05" },
          { fr: "Choix des dates pour chaque graphique", en: "Selection of dates for each display", numero: "06" },
          { fr: "Ajout de nouvelles dépenses et de nouveaux revenus", en: "Addition of new expenses and income", numero: "07" },
          { fr: "Variations des revenus et des dépenses au fil du temps", en: "Variations in income and expenses over time", numero: "08" },
          { fr: "Dépenses et revenus moyens par mois", en: "Average monthly expenses and incomes", numero: "09" },
          { fr: "Suivi de l'atteinte des objectifs mensuels.", en: "Tracking of monthly goal achievement", numero: "10" },
        ],
  },
    {
        id: "elSword_project",
          titre: "ElSword",
          shortDescription: {
            fr: "Jeu vidéo d'aventure",
            en: "Adventure video game",
          },
          image: image,
          description: {
            fr: `
            Ce jeu est un jeu vidéo développé en Python à l’aide de Pygame. J’ai réalisé ce projet en classe de 1ère car j’avais découvert Python et l’informatique dans le cadre de la spécialité NSI (Numérique et Sciences de l’Informatique). L'objectif était de créer un jeu d'aventure où le joueur peut affronter plusieurs ennemis différents, chacun ayant ses propres patterns et intelligence artificielle.

Ce jeu comporte deux modes : le mode histoire et un mode infini. Le mode histoire est une succession d’ennemis à battre pour arriver à la fin du jeu. Le mode infini est un jeu sans fin où les ennemis apparaissent en boucle. En tuer un rapporte des points, et le but est de faire le plus de points possible.

Ce projet était très intéressant à réaliser car c’est le premier gros projet que j’ai mené à bien. Je venais de découvrir Python et je n'étais pas encore expérimenté. Cela m’a permis d’acquérir des compétences et m’a conforté dans l’idée de poursuivre l’informatique.`,
            en: `
            This game is a video game developed in Python using Pygame. I created this project in my 11th grade class as I had discovered Python and computer science as part of the NSI (Numérique et Sciences de l’Informatique) specialization. The goal was to create an adventure game where the player can face several different enemies, each with its own patterns and artificial intelligence.

The game has two modes: story mode and endless mode. The story mode is a succession of enemies to defeat to reach the end of the game. The endless mode is an endless game where enemies appear in a loop. Killing one earns points, and the goal is to score as many points as possible.

This project was very interesting to carry out because it was the first major project I successfully completed. I had just discovered Python and I was not yet experienced. It allowed me to acquire skills and confirmed my interest in pursuing computer science.`,
          },
          images: [{img: elsword1, alt: "Image du projet ElSword"}, {img: elsword2, alt: "Image du projet ElSword"}],
          video: elsword3,
          technos: ["Python", "Pygame"],
          explicationTitre: { fr: "Ennemis", en: "Enemies" },
          explicationCard: 
            [
              { fr: "Sanglier : fait des allers-retours sur la carte.", en: "Boar: Moves back and forth on the map.", numero: "01" },
              { fr: "Fantôme : Vole au-dessus du joueur et descend sur lui dès qu’il est derrière.", en: "Ghost: Flies above the player and swoops down on them when behind.", numero: "02" },
              { fr: "Dragon : Tire des boules de feu", en: "Dragon: Shoots fireballs", numero: "03" },
              { fr: "Boxeur : S’échauffe puis fonce sur le joueur et le tue en un coup.", en: "Boxer: Warms up then charges at the player and kills them in one hit.", numero: "04" },
              { fr: "Frères : Font des allers-retours en donnant des coups de marteau.", en: "Brothers: Move back and forth while swinging hammers.", numero: "05" },
            ],
        },

        {
            id: "tricount_project",
              titre: "TriCount ",
              shortDescription: {
                fr: "Logiciel pour faire ses comptes entre amis.",
                en: "Software for managing expenses between friends.",
              },
              image: image,
              description: {
                fr: `
                Lors d'un voyage avec des amis, nous avions prévu d'utiliser l'application Tricount. Pour me perfectionner et réaliser un projet, j'ai décidé de recréer une application similaire. Je l'ai développée en Java, en utilisant JavaFX, et j'ai adopté une architecture Modèle-Vue-Contrôleur (MVC).`,
                en: `
                During a trip with friends, we planned to use the Tricount application. To improve my skills and undertake a project, I decided to recreate a similar application. I developed it in Java, using JavaFX, and adopted a Model-View-Controller (MVC) architecture.`,
              },
              images: [{img: elsword1, alt: "Image du projet Tricount"}, {img: elsword1, alt: "Image du projet Tricount"}],
              technos: ["Java", "JavaFX"],
              explicationTitre: { fr: "Parcours utilisateur", en: "User flow" },
              explicationCard: 
                [
                  { fr: "L'utilisateur lance l'application.", en: "The user launches the application.", numero: "01" },
                  { fr: "Il crée un nouveau compte partagé.", en: "They create a new shared account.", numero: "02" },
                  { fr: "Il ajoute des participants au compte.", en: "They add participants to the account.", numero: "03" },
                  { fr: "Il enregistre les dépenses en précisant qui a payé quoi.", en: "They record expenses, specifying who paid for what.", numero: "04" },
                  { fr: "Il consulte la balance pour connaître les dettes entre les participants.", en: "They check the balance to see debts between participants.", numero: "05" },
                ],
                
              
            },
  
];
