import React, { useEffect } from "react";
import photo from "../images/photo.jpg";
import cv from "../images/adrien_scher_cv.pdf";
import CircleHoverButton from "../components/CircleHoverButton";
export default function Accueil(props: { lang: string }) {
  const lang = props.lang;

  return (
    <>
      <div
        id="accueil"
        className="left-to-right w-full relative lg:w-4/5 mx-auto flex flex-col lg:flex-row pt-24 lg:pt-32"
      >
        <div className="w-full lg:w-2/3 text-black dark:text-light lg:px-0 px-4">
          <p className="text-4xl lg:text-7xl font-normal mb-2 lg:text-left text-center">
            {lang === "FR" ? "Bonjour, je suis" : "Hi, I'm"}
            </p>
          <h2 className="text-5xl lg:text-7xl font-bold lg:text-left text-center text-lightThird dark:text-darkThird">
              Adrien Scher 
          </h2>

          <p className="pt-6 lg:pr-44 text-center md:text-left text-sm">
            {
              lang === "FR" ? 
              "Étudiant ingénieur, alternant et auto-entrepreneur, découvrez mon portfolio."
              :
              "Engineering student, apprentice, and self-employed entrepreneur, discover my portfolio."
            }
          </p>

          <div className="flex flex-row flex-wrap justify-around lg:justify-start pt-10 lg:pt-12">
            <CircleHoverButton
              href="mailto:adrienscher.fr"
              className="shadow-lg dark:shadow-none rounded-lg lg:mr-8 text-center hover:text-white hover:dark:text-black"
              texte={`${lang === "FR" ? "Contactez-moi" : "Contact me"}`}
              textColor={"text-lightText dark:text-light"}
              bgColor={"bg-lightPrimary dark:bg-darkPrimary"}
              beforBgColor={"before:bg-lightThird before:dark:bg-darkThird"}
              w={"w-fit"}
              px="px-4"
              py="py-4"
            />

            <CircleHoverButton
              href={cv}
              className="rounded-lg lg:mr-8 text-center border-2 border-lightPrimary dark:border-darkPrimary"
              texte={`${lang === "FR" ? "Mon CV" : "My resume"}`}
              textColor={"text-dark dark:text-light"}
              bgColor={"bg-light dark:bg-dark"}
              beforBgColor={
                "before:bg-lightPrimary before:dark:bg-darkPrimary"
              }
              w={"w-fit"}
              px="px-4"
              py="py-4"
            />
          </div>
        </div>

        <div className="w-full lg:w-1/3 pb-10 lg:pb-0 pt-10 lg:pt-0">
          <div className="bg-dark main-div w-72 h-72 lg:w-80 lg:h-80 xl:w-[28rem] xl:h-[28rem] rounded-full relative overflow-hidden mx-auto">
            <img
              src={photo}
              alt="Photo de adrien scher"
              className="main-image scale-110 hover:scale-100 hover:grayscale hover:opacity-50 transition-transform duration-500 ease-in-out"
            />
            <div className="top-to-bot main-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-light hidden">
              <p>Adrien scher</p>
              <p className="underline underline-offset-4 decoration-lightThird dark:decoration-darkThird">
                Ingénieur informatique
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
