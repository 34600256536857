import React, { useEffect } from "react";
import APropos from "../sections/Apropos";
import ThemeSection from "../components/ThemeSection";
import Projects from "../sections/Projects";
import Experience from "../sections/Experience";
import Competence from "../sections/Competence";
import Accueil from "../sections/Accueil";
import NavBar from "../components/NavBar";
import {sections} from "../contents/content";
import Footer from "../components/Footer";
import LangageToggle from "../components/LangageToggle";
import { useParams } from "react-router-dom";
import ProjectDetail from "../projects/ProjectDetail";
import CookieConsent from "../components/CookieConsent";


export default function MainPage(props: { sendPageView: any; accepted: any; handleAccept: any; }) {
  const { sendPageView, accepted, handleAccept } = props;
  const [lang, setLang] = React.useState("FR");
  const { projectId } = useParams();

  useEffect(() => {
    sendPageView("/accueil", "Accueil");
}, []);
  return (
    <>
    <ThemeSection />
    <LangageToggle setLang={setLang} lang={lang} />
    {!accepted && <CookieConsent setAccepted={handleAccept} />}
    
    <div className="dark:bg-dark bg-light">
      {
        projectId ? 
        <ProjectDetail projectId={projectId} lang={lang} sendPageView={sendPageView}/> :
        <NavBar lang={lang}/>
      }
        <Footer lang={lang}/>
    </div>
    </>
  );
}
