// SkillCategory.js
import React from "react";

export default function SkillCategory(props: { title: string, className?: string, children: React.ReactNode }) {
  const { title, className, children } = props;
  return (
    <div className={`${className} transition-all	duration-500 hover:scale-95 bg-lightPrimary dark:bg-darkPrimary border-t border-lightThird dark:border-darkThird  w-full mb-6 md:w-[49%] p-4 md:mb-[2%]`}>
      <h2 className={`text-lightText dark:text-light mb-10 text-lg font-semibold text-center`}>{title}</h2>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
        {children}
      </div>
    </div>
  );
}
