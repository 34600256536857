import React from "react";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

export default function Section(props) {
    const { setActiveSection, timeOfLastClick, section, lang } = props;
    const { id } = section;
    const { ref, inView } = useInView({ threshold: 0.7 });
    const sectionRef = useRef<HTMLDivElement>(null); // Add type annotation to useRef

    useEffect(() => {
        const handleScroll = () => {
            if (sectionRef.current) {
                const rect = sectionRef.current.getBoundingClientRect();
                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    setActiveSection(id);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [id, setActiveSection]);

    useEffect(() => {
        console.log(id);

        if (inView && Date.now() - timeOfLastClick > 1000) {
            setActiveSection(id);
        }
    }, [inView, id, setActiveSection, timeOfLastClick]);

    return (
        <div ref={sectionRef} id={id}>
            <div ref={ref}>
                {<section.element lang={lang} />}
            </div>
        </div>
    );
}
